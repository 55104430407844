.wrapper {
  min-height: rem(400px);
  width: 75vw;
  margin: auto;
  background: linear-gradient(to right, rgba(0, 126, 242, 1), rgb(3, 86, 159));
  border-radius: var(--mantine-radius-md);
  padding: calc(var(--mantine-spacing-xl) * 2.5);

  @media (max-width: 576px) {
    padding: calc(var(--mantine-spacing-xl) * 1.5);
  }
  @media (max-width: 720px) {
    width: 95vw;
  }
}

.title {
  
  font-family: 'Open Sans', sans-serif !important;
  color: var(--mantine-color-white);
  line-height: 1;
  @media (max-width: 720px) {
    font-size: 2rem !important;
  }
}

.description {
  color: white !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 1.2rem !important;
  max-width: rem(300px);
  @media (max-width: 720px) {
    font-size: 1rem !important;
  }

  @media (max-width: 576px) {
    max-width: 100%;
  }
}

.form {
  
  background-color: #000000;
  padding: var(--mantine-spacing-xl);
  border-radius: var(--mantine-radius-md);
  box-shadow: var(--mantine-shadow-lg);
  font-family: 'Open Sans', sans-serif !important;
  @media (max-width: 720px) {
    font-size: 0.9rem !important;
  }
}

.social {
  color: var(--mantine-color-white);

 
  @mixin hover {
    color: var(--mantine-color-blue-1);
  }
}

.input {
  background-color: var(--mantine-color-white);
  border-color: var(--mantine-color-gray-4);
  color: var(--mantine-color-black);
  font-weight: 500;
  &::placeholder {
    color: var(--mantine-color-gray-5);
    font-family: 'Open Sans', sans-serif !important;
    font-size: 1.1rem;

    @media (max-width: 720px) {
      font-size: 1rem;
    }
  }
}

.inputLabel {
  color: #fff;
  font-size: 1.1rem !important;
  @media (max-width: 720px) {
    font-size: 1rem;
  }
}

.control {
  background-color: var(--mantine-color-blue-6);
  font-size: 1.1rem !important;
  font-family: 'Open Sans', sans-serif !important;
  @media (max-width: 720px) {
    font-size: 1rem;
  }
}
